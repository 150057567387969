/* eslint-disable require-jsdoc */
import { AppBar, Toolbar } from '@mui/material';
import * as React from 'react';

export function TopBar() {
  return (
    <AppBar position="fixed">
      <Toolbar>b4llzl4bz</Toolbar>
    </AppBar>
  );
}
