import React from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import './App.css';
import { Gallery, TopBar } from './components';

/**
 * App Entry Point
 * @param {*} { user }
 * @return {*}  {JSX.Element}
 */
export default function App(): JSX.Element {
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar />
        <div style={{ width: '100%', marginTop: 64 }}>
          <Gallery />
        </div>
      </Box>
    </ThemeProvider>
  );
}
